import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "gatsby";

import "./styles.scss";

function LanguagePicker() {
  const { i18n } = useTranslation();
  const languages = [
    { code: "pl", label: "Polski", href: "/" },
    { code: "de", label: "Niemiecki", href: "/de/" }
  ];
  
  return (
    <div className="picker">
      {languages.map(({code, label, href}) => (
        <div key={code} className={i18n.language===code ? 'active' : ''}>
          <Link className={`btn ${code} ${i18n.language===code ? 'active' : ''}`} to={href}>
          </Link>
        </div>
      ))}
    </div>
  )
}

export default LanguagePicker;