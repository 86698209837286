/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import CookieConsent from "react-cookie-consent";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import { useLocation } from "@reach/router";
import { withTranslation, useTranslation } from "react-i18next";
import parse from "html-react-parser";

import Header from "./header";
import Footer from "./footer";
import "./layout.css"
import "../styles/colors.scss";

const Layout = ({ children }) => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      <Header />
      <div className="my-o mx-auto p-0">
        <main>{children}</main>
        <Footer/>
      </div>
      <CookieConsent
          location="bottom"
          buttonText={t("seo.cookies.accept")}
          declineButtonText={t("seo.cookies.decline")}
          cookieName="gdpr-google-analytics"
          containerClasses="cookies"
          contentClasses="m-0"
          disableStyles={true}
          buttonClasses="bg--primary"
          onAccept={() => initializeAndTrack(location)}
          onDecline={() => {}}
          enableDeclineButton
        >
          {parse(t("seo.cookies.content"))}
        </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withTranslation()(Layout)
