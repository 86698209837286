import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation, withTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  return (
    <Container fluid className="py-4 bg--info text--light">
      <Container>
        <Row>
          <Col className="col-12 text-center">
            {t("seo.title")}
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default withTranslation()(Footer);