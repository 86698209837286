import React from "react"
import { Navbar, Nav, Container } from "react-bootstrap";
import LanguagePicker from "../components/LanguagePicker/LanguagePicker";
import { withTranslation, useTranslation } from "react-i18next";
import logo from "../images/GERMAGO_logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons'

const Header = () => {
  const { t } = useTranslation();

  const clickHandler = (e) => {
    e.preventDefault();
    scrollToTargetAdjusted(e.target.hash);
  }
  
  function scrollToTargetAdjusted(element) {
    const box = document.querySelector(element);
    const offset = document.querySelector("#header").offsetHeight;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = box.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });   
}

  return (
  <Navbar bg="light" sticky="top" expand="lg" collapseOnSelect id="header">
    <Container fluid className="top-nav">
      <Container className="pr-lg-3">
      <p>
        <a href={`tel:${process.env.USER_PHONE}`}>
          <FontAwesomeIcon icon={faMobileAlt}/>
          <span className="d-none d-md-inline-block">&nbsp;&nbsp;(+48) 695 501 454</span>
        </a>
        <a href={`mailto:${process.env.USER_EMAIL}`}>
          <FontAwesomeIcon icon={faEnvelope}/>
          <span className="d-none d-md-inline-block">&nbsp;&nbsp;{process.env.USER_EMAIL}</span>
        </a>
      </p>
      <p className="d-flex">
        <a href={process.env.FACEBOOK_URL} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faFacebook}/>
        </a>
        <LanguagePicker/>
      </p>
      </Container>
    </Container>
    <Container className="px-3 px-lg-0">
      <a href="/"  className="d-block col-6 col-sm-4 col-lg-3 px-2 py-3">
        <img src={logo} alt="logo" className="m-0 nav-logo"/>
      </a>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link href="#home" onClick={clickHandler}>{t("menu.home")}</Nav.Link>
          <Nav.Link href="#translate" onClick={clickHandler}>{t("menu.translate")}</Nav.Link>
          <Nav.Link href="#learning" onClick={clickHandler}>{t("menu.learning")}</Nav.Link>
          <Nav.Link href="#about" onClick={clickHandler}>{t("menu.about")}</Nav.Link>
          <Nav.Link href="#contact" onClick={clickHandler}>{t("menu.contact")}</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
  )
}

export default withTranslation()(Header);
